import { ApplicationRef, ErrorHandler, Injectable, OnDestroy } from '@angular/core';

import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmationService } from './confirmation.service';
import { Store } from "@ngrx/store";
import { setUpdateAvailableActivity } from "../activity/store";

@Injectable({
  providedIn: 'root'
})
export class UpdateService implements OnDestroy {
  private onDestroy = new Subject<void>();

  constructor(private swUpdate: SwUpdate,
              errorHandler: ErrorHandler,
              private alert: AlertService,
              private translateService: TranslateService,
              private appRef: ApplicationRef,
              private store: Store,
              private confirmationService: ConfirmationService,
  ) {

    appRef.isStable.pipe(
      first(isStable => isStable === true),
      takeUntil(this.onDestroy),
    ).subscribe(async () => await this.swUpdate.checkForUpdate());

    this.swUpdate.versionUpdates.pipe(
      filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
      switchMap(() => combineLatest([
        this.translateService.get('app.updateTitle'),
        this.translateService.get('app.update'),
        this.translateService.get('app.action'),
        this.translateService.get('app.updateInAction'),
      ])),
      takeUntil(this.onDestroy),
    ).subscribe(([title, message, action, updateInAction]) => {
      this.confirmationService.updateWebapp(
        () => {
          this.alert.message(updateInAction, 'info');
          this.updateWebapp();
        },
        title,
        message,
        action,
        () => {
          this.store.dispatch(setUpdateAvailableActivity());
        }
      );
    });

    this.swUpdate.unrecoverable
      .pipe(
        tap(evt => {
          const errorMsg = `Unrecoverable state: ${ evt.reason }`;
          errorHandler.handleError(errorMsg);
        }),
        takeUntil(this.onDestroy),
      )
      .subscribe(() => window.location.reload());
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  updateWebapp() {
    this.swUpdate.activateUpdate().then(() =>
      document.location.reload()
    ).catch(error =>
      console.error(`Failed to apply updates:, ${ error }`)
    );
  }
}
