import { Component, inject, Input } from '@angular/core';
import { DynamicComponent } from '../dynamic.component';
import { Activity } from '../../store';
import { SharedModule } from "../../../shared.module";
import { UpdateService } from "../../../services/update.service";
import { AlertService } from "../../../services/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  standalone: true,
  imports: [SharedModule]
})
export class UpdateAvailableComponent implements DynamicComponent {

  @Input() notification!: Activity;
  private updateService = inject(UpdateService);
  private alert = inject(AlertService);
  private translate = inject(TranslateService);

  updateApp() {
    this.alert.message(this.translate.instant('app.updateInAction'), 'info');
    this.updateService.updateWebapp();
  }
}
