import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { AppState } from 'src/app/store';
import { selectActivitySideBar, toggleSidebar } from 'src/app/core/activity/store';
import { APP_INITIALIZED, THEMEABLE_URL_PATTERNS } from 'src/app/core/app.constants';
import { Observable, shareReplay } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { IProduct } from '../product/model/product.model';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { OnDestroy } from "../../core/shared/rxjs-helpers";


@Component({
  selector: 'app-main-content-sidenav',
  templateUrl: './main-content-sidenav.component.html'
})
export class MainContentSidenavComponent extends  OnDestroy implements OnInit, AfterViewInit {
  @Input() product: IProduct;

  toggled$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
    super()
  }

  ngAfterViewInit(): void {
    /**
     * This method is used to dispatch an action to the store to indicate that the app has been initialized.
     */
    this.store.dispatch(APP_INITIALIZED());
  }

  isThemeable() {
    const baseUrl = this.router.url.split('?')[0];
    return THEMEABLE_URL_PATTERNS.some(pattern => pattern.test(baseUrl));
  }

  ngOnInit(): void {
    this.toggled$ = this.store.select(selectActivitySideBar);
  }

  toggleActivityFeed() {
    this.store.dispatch(toggleSidebar({ orgId: null }));
  }

}

