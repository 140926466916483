<main class="min-h-screen flex-col w-full font-body bg-slate-950 flex justify-between overflow-hidden"
  [ngClass]="{
    'group/container themeable': isThemeable()
  }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">

  <app-product-top-nav [product]="product"></app-product-top-nav>
  <div class="flex-1 flex justify-between h-full">
    <div class="flex justify-between z-40 sticky top-0 bottom-0 dark:border-slate-800/60 border-none">
      <router-outlet name="sidebar"></router-outlet>
    </div>

    <section cdkScrollable class="flex-1 flex flex-col justify-between relative overflow-x-hidden">
      <div class="flex-1 flex flex-col justify-between bg-soft-blue dark:bg-slate-950 transition-all">
        <header class="w-full text-sm px-10 dark:text-white dark:border-transparent">
          <div class="-mx-10">
            <router-outlet name="notifications"></router-outlet>
          </div>
        </header>
        <div class="grow px-6 relative transition-all flex flex-col justify-between py-0">
          <div class="flex-1">
            <router-outlet></router-outlet>
          </div>
          <div class="py-1.5"></div>
        </div>
      </div>
    </section>
  </div>
</main>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="toggled$ | async">
  <div
    class="fixed right-0 bottom-0 top-0 fade-in-left overflow-y-auto w-[370px] bg-white shadow-lg z-[999] p-4 space-y-8">
    <div class="flex items-center justify-between">
      <div class="font-medium text-sm flex items-center space-x-2.5">
        <app-svg-images svgName="bell-alert" class="h-5"></app-svg-images>
        <span>{{ 'app.navigation.notifications.title' | translate }}</span>
      </div>
      <button class="p-1 active" (click)="toggleActivityFeed()" class="h-5">
        <app-svg-images svgName="x-mark"></app-svg-images>
      </button>
    </div>
    <div class="space-y-3">
      <app-activity-content></app-activity-content>
    </div>
  </div>
</ng-template>

<div [ngClass]="{ 'group/container themeable': isThemeable()}">
  <router-outlet name="aux"></router-outlet>
</div>
