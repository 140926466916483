<mat-card appearance="outlined" class="mb-3 !bg-orange-300/60" [ngClass]="{'active': !notification.read }">
  <mat-card-header class="mb-0">
    <mat-card-title class="title mb-0">
      <span class="text-sm">
        {{ 'app.updateTitle' | translate }}
      </span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span class="text-xs">{{ 'app.update' | translate }}</span>
  </mat-card-content>
  <mat-card-footer class="p-2">
    <app-button type="button" (click)="updateApp()">
      {{ 'app.action' | translate }}
    </app-button>
  </mat-card-footer>

</mat-card>
